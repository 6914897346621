.m-formRows {
  margin: 0 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 12px 12px;
  align-items: center;
}

.a-formTextArea {
  &__label {
    grid-column: 1/1;
    text-transform: uppercase;
  }

  &__input {
    grid-column: 2/3;
    resize: vertical;
  }
}