.o-sacDigitsBackwards {
  &__listDisplay {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 20px;

    &__list {
      padding: 0 10px 10px;
      margin: 0;
      list-style-type: none;

      display: flex;
      flex-direction: column;
      align-items: center;

      border: thin #CDCDCD solid;
    }

    &__list:hover,:active {
      border-color: #CC2401;
    }
  }
  
  &__formDisplay {
    &__scoreCol {
      grid-column: 3/3;
      margin: auto;
      width: 80px;
      text-align: center;
    }
  }
}