.results-card {
    display: inline-block;
    margin: 10px;
    width: 400px;

    &__header {
        text-align: center;
        padding: 20px;
        border: solid thin #E5E5E5;
        margin-bottom: 0;
        background-color: #E5E5E5;
        font-weight: 500;
    }

    &__body {
        padding: 10px;
        border: solid thin #E5E5E5;

        &__table {
            margin-bottom: 40px;
        }

        &__row {

            &__header {
                text-transform: uppercase;
                font-weight: 900;
                text-align: left;
            }

            &__cell {
                padding: 10px 10px 10px 50px;
            }
        }
    }
}