.account-login-form {
    margin: 0 0 0 20px;


    &__container {
        width: 320px;
        margin:0 auto;
        margin-top: 50px;
    }

    &__heading {
        margin: 30px;

        &__logo, &__text {
            width: fit-content;
            display: block;
            margin: 0 auto;
        }
    }

    &__label {
        display: block;
    }

    &__status-message {
        border-radius: 4px;
        padding: 5px 10px;

        &--success {
            border: solid;
            border-color: #3c763d;
        }

        &--error {
            border: solid;
            border-color: #CC2401;
        }

        &--info {
            border: solid;
            border-color: #31708f;
        }

        &__container {
            width: fit-content;
            margin: 0 auto;
        }
    }

    &__submit-button__container {
        float:right;
        margin: 10px 0;
    }

    .common-form__input {
        margin: 10px 0;
    }
}
