
@keyframes screen-fade {
    from {background: rgba(77, 77, 77, 0)}
    to {background: rgba(77, 77, 77, 0.8)}
}

.common-drawer {
  &__h1 {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #dddddd;
  }
  &__p {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    margin-bottom: 20px;
    color: #dddddd;
  }
}

.common-drawer-outer {
	position:fixed;
	width:100%;
	height:100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(77, 77, 77, 0.8);
	z-index: 1500;

	animation-name: screen-fade;
 	animation-duration: 0.5s;

}

	.common-drawer-inner {
		background: #333333;
		padding:40px;
		position:fixed;
		z-index: 1500;
		top:0;
		right:0;
		bottom:0;
		width:90vw;
		max-width:520px;
		transition:transform 0.5s;
		transform: translateX(100%);

  // &:before {
  //   content: '';
  //   background: url('/img/components/white-logo.svg');
  //   width: 162vw;
  //   height: 100%;
  //   position: fixed;
  //   opacity: .05;
  //   top: 0px;
  //   left: -371px;
  //   background-size: 82%;
  //   z-index: 0;
  //   background-repeat: no-repeat;
  //   pointer-events: none;
  // }
		overflow-y:scroll;
		overflow-x:hidden;

		&.open {
			transform: translateX(0%);

			max-height: none;
		}

	}