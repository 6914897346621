.nav-menu {

    text-transform: uppercase;
    font-weight: 900;
    user-select: none;
    width: fit-content;

    &__button {
        &__text {
            margin-top: 1px;
            display: inline-block;
        }

        &__img {
            display: inline-block;
            width: 9px;
            margin-left: 9px;
        }
    }

    &__options {

        text-align: center;
        padding: 5px;
        cursor: pointer;

        a:link,a:visited {
            color: #CC2401;
            text-decoration: none;
        }

        &--closed {
            visibility: hidden;
        }
    }
}