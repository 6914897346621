
.common-styled-dropdown {
    border-color: #CC2401;
    border-style: solid;
    border-radius: 4px;
    width: fit-content;
    margin: 0px auto 20px auto;
    text-transform: uppercase;
    font-size: .7em;
    font-weight: 900;

    &__label {
        display: inline-block;
        background-color: #CC2401;
        padding: 11.5px 18px;
        color: white;
        text-align: center;
        width: -webkit-fill-available;
    }


    &__dropdown__container{
        white-space: nowrap;
        align-items: center;
    }

    &__dropdown { 
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }
        font-size: 1.2em;
        width: 100%;
        cursor: pointer;
        display: inline-block;
        grid-template-areas: "select";
        grid-area: select;
        align-items: center;

        select {
            // A reset of styles, including removing the default dropdown arrow
            appearance: none;
            // Additional resets for further consistency
            background-color: transparent;
            color: #CC2401;
            text-transform: uppercase;
            border: none;
            padding: 0 10px 0 0;
            margin: 0;
            width: 100%;
            height: 32px;
            font-family: inherit;
            font-size: inherit;
            font-weight: 900;
            cursor: inherit;
            line-height: inherit;
            text-align: center;
            text-align-last: center;
          }
    }
    &__dropdown::after {
        display: inline-block;
        content: "";
        width: 1em;
        height: 1em;
        border-radius: 50%;
        border-color: white;
        border-style: solid;
        background: #CC2401 url('data:image/svg+xml;utf8,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path transform="translate(2.8, 4.2)" d="M9.53674e-07 -3.49691e-07L2.77551 -2.2837e-07L4 1.85526L5.26531 -1.19537e-07L8 0L4 6L9.53674e-07 -3.49691e-07Z" fill="white"/></svg>') no-repeat;
        position: relative;
        top: 4px;
        left: -8px;
        pointer-events: none;
      }
}

@media (min-width: 760px) {
.common-styled-dropdown {
    &__label {
      width: auto;
    }

    &__dropdown__container{
      display: inline-block;
    }
  }
}