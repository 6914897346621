.o-scat5 {

    &__formContainer {
        height: 60vh;
        max-width: 800px;
        overflow-y: auto;
        margin: 0 auto;
    }

    &__buttonContainer {
        margin: 20px auto;
        width: 500px;
        display: flex;
        justify-content: space-around;
    }

    &__pageIndicator {
        text-align: center;
        margin-top: 15px;
    }
}