
.app-bar{
    margin: 0px auto;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 100px 80px;
}



.app-bar__menu__container{
    min-width: -webkit-min-content;
    grid-column: 1;
    grid-row: 1;
    margin-top: 48px;

}

.app-bar__logo {
    width: 128px;
}

.app-bar__page-name__container {
    h1 {
        font-size: 1.9em;
        white-space: nowrap;
        margin: 0px auto 20px;
    }
    margin: 20px auto 0px;
    text-align: center;
    text-transform: uppercase;
    grid-column: 2;
    grid-row: 1;
}

.user-dropdown__container {
    min-width: -webkit-min-content;
    margin: 0px auto 0px;
    width: fit-content;
    grid-column: 2;
    grid-row: 2;
}

@media (min-width: 500px) {

    .app-bar {
        grid-template-rows: 100px 50px;
    }

    .user-dropdown__container {
        grid-column: 3;
        grid-row: 1;
        margin: 55px auto 0px 0px;
        margin-left: auto;
        margin-right: 0px;
    }
    
    .app-bar::before {
        content: "";
        grid-column:1;
        grid-row: 1;
        width:110px;
    }

    .app-bar::after {
        content: "";
        grid-column:3;
        grid-row: 1;
        width:110px;
    }
}

