.common-form__label {
    grid-column: 1 / 2;
    text-transform: uppercase;
}
.common-form__input {
    grid-column: 2 / 3;
    border-width: 2px;
    border-style: solid;
    border-color: #E5E5E5;
    height: 50px;
    font-size: 1.5em;

    &--error {
        border-color: #CC2401;
        border-style: dashed;
    }
}
.common-form__validation-error {
    color: #CC2401;
    font-size: 0.75em;
    grid-column: 1 / 3;
    margin: 0px 0 5px auto;
}
