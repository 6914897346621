.data-table {
    text-transform: uppercase;
    border-collapse: collapse;
    width: 100%;

    &__container {
        margin: 20px 5px;
    }
}

@media (min-width: 500px) {

    .data-table__container{
        margin:20px;
    }

}