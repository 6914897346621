@font-face {
	font-family: 'Renner-it';
	src: url('/resources/fonts/renner/OpenType/Renner_500_Medium.otf') format('opentype');
    font-style: normal;
    font-weight: 500;
}
@font-face {
	font-family: 'Renner-it';
	src: url("/resources/fonts/renner/OpenType/Renner_900_Black.otf") format('opentype');
    font-style: normal;
    font-weight: 900;
}
@font-face{
	font-family: 'Renner-it';
	src: url("/resources/fonts/renner/OpenType/Renner_700_Bold.otf") format('opentype');
    font-style: bold;
    font-weight: 700;
}

body {
    position: absolute;
    margin:0;
    padding:0;
    width: 100%;
}
.app-wrapper {
    font-family: Renner-it;
    padding:10px;
    letter-spacing: 0.15em;
}

.page-wrapper{
    max-width:1200px;
    margin:0 auto;
}

li {
    margin: 0 0 5px 0;
}

button {
    margin: 0 5px;
}

a:link, :visited {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #CC2401;
}

a:active {
    color: grey;
}

@media (min-width: 500px) {

    .app-wrapper{
        padding:10px 20px;
    }

}


