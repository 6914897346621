.a-commonButton {
    border: 0;
    color: white;
    font-family: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    background-color: #CDCDCD;
    border-radius: 4px;
    width: fit-content;
    padding: 10px 15px;
    user-select: none;
    cursor: pointer;
    display:block;
    text-align: center;

    &.-red {
        color: white;
        background-color: #CC2401;
    }
}

@media (min-width: 760px) {
    .commonButton {
        white-space: nowrap;
    }
}