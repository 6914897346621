.o-athleteBulkImport {
  &__message {
    width: fit-content;
    max-width: 500px;
    margin: 0 auto;

    &__error {
      border: #CC2401 dashed;
      padding: 5px;
      margin: 5px 0;
    }
  }
}