.user-dropdown{
    text-transform: uppercase;
    position: relative;
    user-select: none;
    font-weight: 900;

    &__username {
        padding: 5px;
        white-space: nowrap;
        color: #CC2401;
        position: relative;
        cursor: pointer;

        &__text {
            display: inline-block;
        }

        &__img {
            display: inline-block;
            margin-left: 9px;
        }

    }

    &__menu {

        text-align: center;
        padding: 5px;
        position: absolute;
        left: 0px;
        right: 0px;

        a:link,a:visited {
            color: #CC2401;
            text-decoration: none;
        }

        &--closed {
            visibility: hidden;
        }
    }
}