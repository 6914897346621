.side-navigation{
    margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;

    &__left {
        display: flex;
        width: 120px;
        align-items: center;
        justify-content: space-evenly;

        &__text {
            margin: 0 5px;
        }
    }

    &__right {
        display: flex;
        width: 120px;
        align-items: center;
        justify-content: space-evenly;

        &__text {
            margin: 0 5px;
        }
    }
}